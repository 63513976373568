import React from 'react'
import { Link } from 'gatsby-material-ui-components'
import GenericPage, { GenericPageProps } from './Page'
import { Surface } from '@components'
import { Box } from '@mui/material'

/**
 * Placeholder. Do not use in production
 * @param param0
 * @returns
 */
export type PlaceholderPageProps = GenericPageProps & {}

const Placeholder = ({ ...pageProps }: PlaceholderPageProps) => {
  return (
    <GenericPage {...pageProps}>
      <Surface>
        <Box p={3}>
          <pre>
            {'Under Construction. This page requires data. '}
            <Link to="/dashboard/">Go To Dashboard.</Link>
          </pre>
        </Box>
      </Surface>
    </GenericPage>
  )
}

export default Placeholder
